<template>
  <div class="exchangeSuccess">
    <!-- 兑换成功 -->
    <b-card v-if="$route.query.type==1">
      <div class="icon">
        <img src="@/assets/images/paySuccess.png" alt="" />
      </div>
      <div class="details">
        <span>Exchange succeeded</span>
      </div>
      <div class="btnGroup">
        <button class="btn btn-home" @click="$router.push('/webstore')">
          {{ $t("PayMoney.f89@homepage") }}
        </button>
        <button class="btn btn-primary" @click="$router.push('/myexchange')">
          {{ $t("PayMoney.2a1@view_order") }}
        </button>
      </div>
    </b-card>
    <!-- 兑换失败 -->
    <b-card v-else>
      <div class="icon">
        <img src="@/assets/images/pointsOrder/failed.png" alt="" />
      </div>
      <div class="details_failed">
        <span>
          <!-- Exchange failed. Please buy again! The failure reason is: The request is missing one or more required
          fields.Examine the reply fields missingField_0 through missingField_N to find which fields are missing. Resend
          the
          request with the complete information. Ref number :(101) -->
          {{$route.query.msg}}
        </span>
      </div>
      <div class="btnGroup">
        <button class="btn btn-primary" @click="$router.go(-2)">
          {{ $t("PayMoney.0d7@confirm") }}
        </button>
      </div>
    </b-card>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      };
    },
    mounted() {

    },
    methods: {

    },
  };
</script>

<style lang="less" scoped>
  .exchangeSuccess {
    padding: 20px;
    margin: 0 auto;
    width: 690px;

    .card-body {
      padding: 50px;

      .icon {
        text-align: center;
      }

      .details {
        border: 1px solid #49f0d3;
        padding: 10px;
        color: #49f0d3;
        background: rgba(238, 255, 242, 1);
        text-align: center;
        margin-top: 50px;

        span {
          display: block;
        }
      }

      .details_failed {
        border: 1px solid #ff9b8a;
        padding: 10px;
        background: rgba(255, 245, 244, 1);
        color: #ff9b8a;
        text-align: center;
        margin-top: 50px;

        span {
          display: block;
        }
      }

      .btnGroup {
        text-align: center;
        padding-top: 50px;

        .btn-home {
          border: 1px solid #50b5ff;
          margin-right: 10px;
          color: #50b5ff;
        }
      }
    }
  }
</style>